import TitleUnderlined from "utils/components/TitleUnderlined"
import {FormattedMessage} from "react-intl"
import {Link} from "react-router-dom"
import {HOME_PAGE} from "routes"
import {getTranslatedLink} from "services/instances"

export default function SamplesCartSuccess() {

  const idForGTM = 'request-samples'
  
  return (
      <div className={`samples-cart-success text-center py-5 ${idForGTM}`} id={idForGTM} >
        <div className="container">
          <TitleUnderlined
              align="center">
            <FormattedMessage
                id="SamplesCartSuccess.title"
                defaultMessage="Orden recibida"/>
          </TitleUnderlined>
          <p>
            <FormattedMessage
                id="SamplesCartSuccess.subtitle"
                defaultMessage="Gracias. Tu orden fue recibida. Pronto nos pondremos en contacto contigo."/>
          </p>
          <Link
              to={getTranslatedLink(HOME_PAGE)}
              className="btn btn-dark">
            <FormattedMessage
                id="SamplesCartSuccess.homeBtnLabel"
                defaultMessage="Volver al inicio"/>
          </Link>
        </div>
      </div>
  )
}